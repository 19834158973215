@import "../../global.scss";

.pl {
  min-width: 100vw;
  background-color: $white;
  // background-color: $mainColor;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-texts {
  width: 65%;
}

.pl-title {
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 500;
  line-height: 1.6em;
  text-transform: uppercase;

  color: hsl(224, 38%, 55%);
  @media screen and (min-width: 810px) {
    font-size: 3.5em;
  }
}

.pl-desc {
  margin: 20px 0px;
  color: black;
  font-size: 1em;

  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 10px;
  }

  .pl-title {
    font-size: 30px;
    padding: 20px;
  }

  .pl-texts {
    width: 100%;
  }

  .pl-desc {
    display: none;
  }
}
