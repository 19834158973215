@import "../../global.scss";

* {
  box-sizing: border-box;
}

.outro {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: $mainColor;

  a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    min-height: 120vh;
    overflow-y: hidden;
  }
}

.outro-getintouch {
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;

  margin-top: 0.5em;
  font-size: 2.2rem;
  font-weight: 300;
  color: hsl(224, 38%, 55%);
  text-align: center;

  @media screen and (min-width: 810px) {
    text-align: right;
    // padding-left: 15em;
  }
}

.outro-sendemail {
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
  font-size: 1.7rem;
  color: white;

  text-align: right;
  padding: 3rem 1rem 3.5rem 0rem;

  @media screen and (min-width: 810px) {
    font-size: 2.5rem;
    color: white;
  }
  @media screen and (min-width: 1600px) {
    padding: 8rem 0 3.5rem 0rem;
    font-size: 2.5rem;
    color: white;
  }
}

.intro-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-left-wrapper {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intro-right {
  flex: 1;
  position: relative;
}

.intro-bg {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  background: rgb(0, 12, 51);
  background: linear-gradient(
    176deg,
    rgba(0, 12, 51, 1) 35%,
    rgba(0, 84, 134, 1) 51%,
    rgba(0, 141, 177, 1) 70%,
    rgba(0, 172, 200, 0) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.intro-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.intro-img {
  height: 50%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.intro-skills {
  // padding-left: 15rem;
  height: 50px;
  overflow: hidden;
}

.intro-skills-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;

  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
  // text-align: right;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.intro-skills-item {
  height: 50px;

  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
  color: hsl(224, 38%, 55%);

  display: flex;
  align-items: center;
  overflow: visible;
  font-size: 16px;

  @media screen and (min-width: 810px) {
    font-size: 2rem;
  }
}

.intro-logo {
  height: 7rem;
  z-index: 2;
  position: absolute;
  right: 890px;
}

@media screen and (max-width: 480px) {
  .intro-left-wrapper {
    padding: 10px;
    // align-items: right;
    // text-align: center;
    height: 100%;
    overflow: hidden;
  }

  .intro-desc {
    display: none;
  }

  .intro-logo {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .intro-skills {
    padding-left: 7rem;
  }
}

@media screen and (max-width: 1880px) {
  .intro-logo {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .intro-skills {
    padding-left: 23rem;
    height: 50px;
    overflow: hidden;
  }
}

@media screen and (min-width: 1600px) {
  .intro-title {
    padding-left: 13rem;
    font-size: 5.5rem;
    font-weight: 300;
    color: hsl(224, 38%, 55%);
  }

  .intro-skills {
    padding-left: 31rem;
    height: 50px;
    overflow: hidden;
  }
}
