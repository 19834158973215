@import "../../global.scss";

@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400");

* {
  box-sizing: border-box;
}

.image-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 810px) {
    justify-content: space-around;
  }
}

.start {
  display: flex;
  padding: 2em;
  // height: 50vh;
  width: 100%;
  background-color: var(--color-lightblue);
}

.start-left {
  z-index: 2;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .row {
    display: flex;
  }

  /* Create three equal columns that sits next to each other */
  .column {
    flex: 33.33%;
    padding: 2px;
  }
}

.intro-bg {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  background: rgb(0, 12, 51);
  background: linear-gradient(
    176deg,
    rgba(0, 12, 51, 1) 35%,
    rgba(0, 84, 134, 1) 51%,
    rgba(0, 141, 177, 1) 70%,
    rgba(0, 172, 200, 0) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.leaf-img {
  height: 150px;
  // width: 100%;
}
