@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;

  color: white;
  position: fixed;
  top: 0;
  z-index: 101;
  transition: all 1s ease;
  opacity: 0.6;

  @include mobile {
    width: 100%;
  }

  .wrapper {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 2.3rem;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;

        @include mobile {
          display: none;
        }

        .icon {
          font-size: 18px;
          margin-right: 5px;
        }

        span {
          font-size: 15px;
          font-weight: 500;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }

    .right {
      padding-right: 3em;
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 2s ease;
          @include mobile {
            background-color: white;
          }
        }
      }
    }
  }

  &.active {
    background-color: $secondColor;
    background: rgb(0, 12, 51);
    background: linear-gradient(
      176deg,
      rgba(0, 12, 51, 1) 35%,
      rgba(0, 84, 134, 1) 51%,
      rgba(0, 141, 177, 1) 70%,
      rgba(0, 172, 200, 0) 100%
    );
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.t-logo-text {
  display: flex;
  a {
    font-size: 2.5rem;
    font-family: "Cormorant SC", serif;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }
}

.t-logo-text {
  @include mobile {
    display: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.t-logo-sm {
  display: none;
}

@media screen and (max-width: 1880px) {
  .t-logo-text {
    display: none;
  }
  .t-logo-sm {
    display: flex;
    width: 1.5em;
    height: auto;
  }
}
