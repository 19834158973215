.header-container {
  display: flex;
  height: 40vh;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://t3.ftcdn.net/jpg/01/90/86/66/360_F_190866679_0HmKN5JBm7TXrBhB3r2rd20DmAlY0EAq.jpg)
      center/cover no-repeat fixed;
  /* min-height: 15vh; */
  position: relative;
}

.developer-title {
  color: var(--color-lightblue);
  font-size: 2.5rem;
  font-family: var(--font-family-montserrat);

  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
  padding: 5rem 2.5rem;
  @media screen and (min-width: 810px) {
    padding: 5rem 0rem 0 6rem;
  }
  @media screen and (min-width: 1080px) {
    padding: 5rem 0rem 0 10rem;
  }
}

.s-container {
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;

  font-weight: 400;
  font-style: normal;
}

.developer-skills-wrapper {
  padding-bottom: 10.5rem;

  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;

  @media screen and (max-width: 810px) {
    padding-left: 2rem;
  }
  @media screen and (min-width: 810px) {
    padding-bottom: 0rem;
    /* padding-left: 13rem; */
  }
}

.s-wrapper {
  display: flex;
  align-items: center;
  padding: 2rem;
  height: 80vh;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://ychef.files.bbci.co.uk/1600x900/p01wjqwx.webp) center/cover
      no-repeat fixed;
  position: relative;
}
