@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400");

$mainColor: #282828;
$secondColor: hsl(224, 38%, 55%);
$white: #fafafc;

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

:root {
  --color-lightblue: #becee8;
  --font-family: "Afacad", "Afacad Placeholder", sans-serif;
  --font-family-montserrat: "Montserrat", "Montserrat Placeholder", sans-serif;
}
