.skills {
  display: flex;
  height: 100vh;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://t3.ftcdn.net/jpg/01/90/86/66/360_F_190866679_0HmKN5JBm7TXrBhB3r2rd20DmAlY0EAq.jpg)
      center/cover no-repeat fixed;
  /* min-height: 15vh; */
  position: relative;
}

.s-title {
  padding: 1rem 0;

  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: hsl(224, 38%, 55%);
  
  @media screen and (min-width: 810px) {
    font-size: 3.5em;
    line-height: 1.2em;
  }
}

.s-desc {
  padding-bottom: 0.5rem;

  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.s-wrapper {
  display: flex;
  align-items: center;
  padding: 2rem;
  height: 80vh;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://ychef.files.bbci.co.uk/1600x900/p01wjqwx.webp) center/cover
      no-repeat fixed;
  position: relative;
}

.skillset-txt {
  display: none;
}

@media screen and (max-width: 768px) {
  .skills {
    height: auto;
    overflow: hidden;
  }

  .s-wrapper {
    padding: 1rem;
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  .skillset {
    margin-top: 1rem;
  }
}
