@import "../../global.scss";

.about {
  min-height: 100vh;
  display: flex;

  // height: 100vh;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://t3.ftcdn.net/jpg/01/90/86/66/360_F_190866679_0HmKN5JBm7TXrBhB3r2rd20DmAlY0EAq.jpg)
      center/cover no-repeat fixed;
  /* min-height: 15vh; */
  position: relative;

  align-items: center;
  // background-color: #282828;
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.1em;
  @media screen and (min-width: 768px) {
    line-height: 1.6em;
  }
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  background-color: white;
  @media screen and (min-width: 768px) {
    border-radius: 30px;
  }
}

.a-right {
  flex: 1;
  background-color: white;
  padding: 1em;

  @media screen and (min-width: 810px) {
    border-radius: 30px;
  }
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-exp-img {
  width: 25%;
  height: 25%;
  /* width: 120px;
    height: 120px; */
  object-fit: cover;
  border-radius: 20px;
}

.a-title {
  font-weight: 400;
  color: hsl(224, 38%, 55%);
  @media screen and (min-width: 810px) {
    font-size: 2rem;
  }
}

.a-title:last-child {
  margin-top: 20px;
  font-size: 16px;
  @media screen and (min-width: 810px) {
    font-size: 2rem;
  }
}

.a-sub {
  margin: 20px 0;
  color: var(--color-lightblue);
}

.a-desc {
  font-weight: 300;
  // color: white;
}

.a-exp {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-exp-texts {
  width: 70%;
}
.a-exp-title {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}
.a-exp-desc {
  width: 70%;
  // color: white;
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
    text-align: center;
  }

  .a-left {
    width: 100%;
  }

  .a-card {
    height: 30vh;
  }

  .a-card.bg,
  .a-exp {
    display: none;
  }

  .a-right {
    padding: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .about {
    flex-direction: column;
    text-align: center;
    height: 100vh;
  }

  .a-left {
    width: 100%;
  }

  .a-card {
    height: 30vh;
  }

  .a-exp-desc {
    width: 100%;
  }

  .a-exp-img {
    display: none;
  }

  .a-exp-texts {
    width: 100%;
  }

  .a-right {
    padding: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .about {
    height: 105vh;
  }
}

@media screen and (min-width: 1366px) {
  .a-desc {
    width: 41vw;
  }
}
