@import "../../global.scss";

/*** TOAST END ***/

:root {
	--tr: all 0.5s ease 0s;
	--ch1: #05478a;
	--ch2: #0070e0;
	--ch3: #0070e040;
	--cs1: #005e38;
	--cs2: #03a65a;
	--cs3: #03a65a40;
	--cw1: #c24914;
	--cw2: #fc8621;
	--cw3: #fc862140;
	--ce1: #851d41;
	--ce2: #db3056;
	--ce3: #db305640;
}

@property --bg-success {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 145%;
}


@property --bsc {
  syntax: '<color>';
  inherits: false;
  initial-value: red;
}

.toast-item {
	overflow: hidden;
	max-height: 25rem;
	transition: var(--tr);
	position: relative;
	animation: show-toast 4s ease 3s 1;
}

@keyframes show-toast { 
	0%, 50%, 100% { max-height: 0; opacity: 0; }
	10%, 25% { max-height: 15rem; opacity: 1; }
}

.toast {
	background: linear-gradient(90deg, #1f2333, #22232b);
    color: #f5f5f5;
    padding: 1rem 2rem 1rem 6rem;
    text-align: center;
    border-radius: 0.25rem;
    position: relative;
    font-weight: 300;
    margin: 1rem 0;
    text-align: left;
    // max-width: 15rem;
    transition: var(--tr);
    opacity: 1;
    border: 0.15rem solid #fff2;
    box-shadow: inset 0 0 0.5rem 0 #1d1e26;
}

.toast:before {
	content: "";
    position: absolute;
    width: 6.5rem;
    height: 6.15rem;
    bottom: -0.15rem;
    left: -0.15rem;
    z-index: 0;
    border-radius: 0.35rem;
    background: radial-gradient(circle at 0% 50%, var(--clr), #fff0 5rem), radial-gradient(circle at -50% 50%, var(--bg), #fff0 5rem);
    opacity: 0.5;
}

.toast:after {
	content: "";
    position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    background: radial-gradient(circle at 50% 50%, var(--clr) 1.25rem, var(--brd) calc(1.25rem + 1px) 100%);
    top: 1.2rem;
    left: 1rem;
    border-radius: 3rem;
    padding-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    box-sizing: border-box;
}

.toast h3 {
	font-size: 1.35rem;
	margin: 0;
	line-height: 1.35rem;
	font-weight: 300;
	position: relative;
}

.toast p {
	position: relative;
	font-size: 0.95rem;
	z-index: 1;
	margin: 0.25rem 0 0;
}

.close {
	position: absolute;
	width: 1.35rem;
	height: 1.35rem;
	text-align: center;
	right: 1rem;
	cursor: pointer;
	border-radius: 100%;
}

.close:after {
	position: absolute;
	font-family: 'Varela Round', san-serif;
	width: 100%;
	height: 100%;
	left: 0;
	font-size: 1.8rem;
	content: "+";
	transform: rotate(45deg);
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.close:hover {
	background: var(--clr);
    color: #22232c;
}

.toast-item.success {
	animation-delay: 2s;
}

.toast.success {
	--bg: var(--cs1);
	--clr: var(--cs2);
	--brd: var(--cs3);
}

.toast.success:after,
.icon-success:after {
	content: "L";
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 0.35rem;
    transform: rotateY(180deg) rotate(-38deg);
    text-indent: 0.1rem;
}

.toast a {
	color: #fff;
}

.toast a:hover {
    color: var(--clr);
}

.toast-item.closed {
	max-height: 0;
}

/*** ICONS ***/

.toast-icons {
	background: linear-gradient(90deg, #1f2333, #22232b);
	padding: 1rem 1rem 1.25rem 1rem;
	display: flex;
	justify-content: space-around;
	border-radius: 0.25rem;
	gap: 1.5rem;
	width: 100%;
	box-sizing: border-box;
	margin-top: 1rem;
	margin-bottom: 1rem;
	position: relative;
    border: 0.15rem solid #fff2;
    box-shadow: inset 0 0 0.5rem 0 #1d1e26;
}

.toast-icons:before {
    position: absolute;
    width: calc(100% + 0.3rem);
    height: calc(100% + 0.25rem);
    --bg-help: 45%;
    --bg-success: 45%;
    --bg-warning: 45%;
    --bg-error: 45%;
	--bsc: #fff0;
    background: 
		radial-gradient(circle at 14% var(--bg-help), var(--ch1), #fff0 2rem), 	radial-gradient(circle at 38% var(--bg-success), var(--cs1), #fff0 2rem), 
		radial-gradient(circle at 62% var(--bg-warning), var(--cw1), #fff0 2rem), radial-gradient(circle at 86% var(--bg-error), var(--ce1), #fff0 2rem);
    content: "";
    bottom: -0.15rem;
    border-radius: 0.25rem;
    z-index: 0;
	transition: --bg-help 0.5s ease 0s,  --bg-success 0.5s ease 0s,  --bg-warning 0.5s ease 0s,  --bg-error 0.5s ease 0s,  --bsc 0.5s ease 0s;
	box-shadow: 0 0 0.35rem 0 var(--bsc) inset;
}

.toast-icons:has(label[for=t-success]:hover):before {
    --bg-success: 53%;
	--bsc: var(--cs2);
}

.toast-icon {
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
	position: relative;
	background: radial-gradient(circle at 50% 50%, var(--clr) 1.25rem, var(--brd) calc(1.25rem + 1px) 100%);
	background: radial-gradient(circle at 50% 50%, var(--clr) 1.25rem, #fff0 calc(1.25rem + 1px) 100%), radial-gradient(circle at 50% 50%,  var(--brd) calc(1.25rem + 1px) 100%);
}

.toast-icon:after {
	font-size: 1.75rem;
}
.icon-success:after {
	font-size: 1.5rem;
    padding-bottom: 0.25rem;
}

.icon-success,
.toast-item.success {
	--clr: #03a65a;
	--brd: #03a65a40;
}

#t-success:checked ~ .toast-panel .toast-item.success {
	max-height: 0;
	opacity: 0;
}

input[type=checkbox] {
	display: none;
}

/*** TOAST END ***/
/*** CONTACT START ***/
.c {
  // min-height: 30vh;
  position: relative;
  background-color: $mainColor;
  font-family: var(--font-family-montserrat);
  color: #fff;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
}

.c-bg {
  width: 20px;
  height: 100%;
  background-color: $secondColor;
  position: absolute;
}

.c-wrapper {
  padding: 50px;
  display: flex;
}

.c-left {
  flex: 1;
}

.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-title,
.c-info-item,
.c-desc {
  color: white;
}

.c-title {
  font-size: 60px;
  width: 80%;
}

.c-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  width: 70%;
  font-family: var(--font-family-montserrat);
  color: #fff;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
}

.c-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.c-desc {
  font-weight: 200;
}

form {
  margin-top: 20px;
  font-family: var(--font-family-montserrat);
  color: #fff;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6em;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  background-color: $mainColor;
  color: white;
}

textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  background-color: $mainColor;
  color: white;
}

button {
  border: none;
  padding: 15px;
  background: rgb(0, 12, 51);
  background: linear-gradient(
    176deg,
    rgba(0, 12, 51, 1) 35%,
    rgba(0, 84, 134, 1) 51%,
    rgba(0, 141, 177, 1) 70%,
    rgba(0, 172, 200, 0) 100%
  );
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  padding: 1rem;
}

.footer-text {
  text-align: center;
  padding: 5px;
}

@media screen and (max-width: 480px) {
  .c-wrapper {
    flex-direction: column;
    padding: 0px 50px;
    overflow: hidden;
  }

  .c-title {
    font-size: 30px;
  }

  .c-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  .footer,
  .footer-text,
  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}
