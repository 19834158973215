// @import url("https://fonts.googleapis.com/css?family=Open+Sans:300");
@import "../../global.scss";

$body-bg-color: #282828;
$thumbnail-size: 320px;
$thumbnail-border-radius: 6%;
$thumbnail-transition-duration: 0.65s;
$thumbnail-caption-bg-color: #f8f8f8;
$thumbnail-caption-font-color: #ababab;
$thumbnail-caption-font-size: 16px;
$thumbnail-overlay-bg-color: #000;
$thumbnail-overlay-text: "Code";
$thumbnail-overlay-text-code: "Demo";
$thumbnail-overlay-font-color: $thumbnail-caption-bg-color;
$thumbnail-overlay-font-size: 24px;
$font-family: "Afacad", "Afacad Placeholder", sans-serif;
$secondColor: hsl(224, 38%, 55%);

* {
  box-sizing: border-box;
}

.project-container {
  // padding: 60px 0;
}

.thumb-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: $body-bg-color;
}

.thumbnail {
  position: relative;
  height: $thumbnail-size;
  width: $thumbnail-size;
  border-radius: $thumbnail-border-radius;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow $thumbnail-transition-duration ease;

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  /* Hover transition */
  &:hover {
    box-shadow: 0 20px 15px -8px rgba(0, 0, 0, 0.8);
  }
}

.thumbnail__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.thumbnail__img_code {
  flex: 4 0 auto;
  background-position: center;
  background-size: cover;
  background-image: url(https://i.pinimg.com/originals/0b/b5/96/0bb5963f254c3c4122fa81df7cc4cea3.gif);
  transform: scale(1.2);
  transition: transform $thumbnail-transition-duration ease;

  /* Hover transition */
  .thumbnail:hover & {
    transform: scale(1);
  }

  &:before,
  &:after {
    position: absolute;
  }

  &:before {
    content: $thumbnail-overlay-text;
    top: 50%;
    left: 50%;
    z-index: 10;
    color: $thumbnail-overlay-font-color;
    font-family: $font-family;
    font-size: $thumbnail-overlay-font-size;
    font-weight: 300;
    letter-spacing: 5px;
    line-height: 2;
    text-transform: uppercase;
    background-image: linear-gradient(
      to right,
      $thumbnail-overlay-font-color 0%,
      $thumbnail-overlay-font-color 100%
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 45% 3%;
    transform: translate(-50%, -50%);
    transition: opacity $thumbnail-transition-duration linear,
      transform $thumbnail-transition-duration ease;

    /* Hover transition */
    .thumbnail:hover & {
      opacity: 0;
      transform: translate(-50%, -500%) scale(0.5);
    }
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $thumbnail-overlay-bg-color;
    opacity: 0.3;
    transition: opacity $thumbnail-transition-duration linear;

    /* Hover transition */
    .thumbnail:hover & {
      opacity: 0;
    }
  }
}
.thumbnail__img {
  flex: 4 0 auto;
  background-position: center;
  background-size: cover;
  background-image: url("../../img/demo.png");
  transform: scale(1.2);
  transition: transform $thumbnail-transition-duration ease;

  /* Hover transition */
  .thumbnail:hover & {
    transform: scale(1);
  }

  &:before,
  &:after {
    position: absolute;
  }

  &:before {
    content: $thumbnail-overlay-text-code;
    top: 50%;
    left: 50%;
    z-index: 10;
    color: $thumbnail-overlay-font-color;
    font-family: $font-family;
    font-size: $thumbnail-overlay-font-size;
    font-weight: 300;
    letter-spacing: 5px;
    line-height: 2;
    text-transform: uppercase;
    background-image: linear-gradient(
      to right,
      $thumbnail-overlay-font-color 0%,
      $thumbnail-overlay-font-color 100%
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 45% 3%;
    transform: translate(-50%, -50%);
    transition: opacity $thumbnail-transition-duration linear,
      transform $thumbnail-transition-duration ease;

    /* Hover transition */
    .thumbnail:hover & {
      opacity: 0;
      transform: translate(-50%, -500%) scale(0.5);
    }
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $thumbnail-overlay-bg-color;
    opacity: 0.3;
    transition: opacity $thumbnail-transition-duration linear;

    /* Hover transition */
    .thumbnail:hover & {
      opacity: 0;
    }
  }
}

.thumbnail__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 0;
  background-color: $thumbnail-caption-bg-color;
  overflow: hidden;
  transition: flex-grow $thumbnail-transition-duration ease;

  /* Hover transition */
  .thumbnail:hover & {
    flex-grow: 1;
  }
}

.thumbnail__caption {
  color: $thumbnail-caption-font-color;
  font-family: $font-family;
  font-size: $thumbnail-caption-font-size;
  font-weight: 300;
  line-height: 1.5;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.5) translateY(100%);
  transition: opacity $thumbnail-transition-duration linear,
    transform $thumbnail-transition-duration ease;

  /* Hover transition */
  .thumbnail:hover & {
    transform: scale(1);
    opacity: 1;
  }
}
.thumb-container-sm {
  display: none;
}

// BROWSER WINDOW WITH THE APPLICATION'S ANIMATED SCREENSHOTS

.projects {
  width: 40%;
  height: 75vh; // Default setting
  margin: 50px 0px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}

.p-img {
  width: 100%;
  transition: all 30s ease;
}

.projects:hover .p-img {
  transform: translateY(-100%);
}

/* PROJECTS MEDIA QUERIES */
// !PROJECTS! Browser window - mobile devices size
@media screen and (max-width: 480px) {
  .projects {
    width: 40%;
    height: 60vh;
    // height: 20vh;
  }
}
@media screen and (max-width: 768px) {
  .projects {
    width: auto;
    height: 30vh;
  }
}
@media screen and (max-width: 1000px) {
  .projects {
    height: 30vh;
    margin: 0px 0px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
}
@media screen and (max-width: 1200px) {
  .projects {
    width: 100%;
    height: 30vh;
    margin: 0px 0px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
}
// !PROJECTS! Browser window - PC screen size
@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .projects {
    width: 250px;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1510px) and (min-height: 625px) and (max-height: 767px) {
  .projects {
    width: 30%;
    height: 75vh;
    margin: 100px 0px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
}
@media screen and (width: 1366px) and (min-height: 768px) and (max-height: 1080px) {
  .projects {
    width: 30%;
    height: 75vh;
    margin: 50px 0px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
}
@media screen and (min-width: 1367px) and (max-width: 1700px) {
  .projects {
    width: 31vw;
    height: 95vh;
    background: red;
  }
}

@media screen and (min-width: 1701px) {
  .projects {
    width: 31vw;
    height: 85vh;
  }
}

@media screen and (min-width: 1800px) {
  .projects {
    width: 29vw;
    height: 85vh;
  }
}

// DEVTOOLS WINDOW WITH THE APPLICATION'S DETAILS

.p-devtools {
  margin: 100px 0px;
  height: 75vh;
  border: 1px solid rgb(243, 242, 242);
  border-radius: 10px;
  overflow: hidden;
}

.p-browser {
  min-height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
}

.p-title {
  margin: 3px;
  padding-left: 10px;
  color: gray;
}
.p-dev-tools {
  border: 0.5px solid lightgray;
  height: 100%;

  .p-dev-browser {
    min-height: 20px;
    min-width: 300px;
    background-color: rgb(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
    color: black;
    cursor: default;

    p {
      padding: 10px;
    }
    p:nth-child(2) {
      border-bottom: 1px solid blue;
    }
  }
  .p-info {
    background-color: white;
    transition: all 1s ease-in-out;
  }

  .p-info:hover {
    background-color: blue;
    color: white;
  }
  .app-details {
    text-align: left;
    // font-family: Arial, Helvetica, sans-serif
    // font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 400;
    background-color: white;
    color: black;
    height: 100%;
    cursor: default;

    .console {
      border-bottom: 0.2px solid lightgray;
      width: 100%;
      padding: 3px 10px;
      font-style: italic;
      font-size: 14px;
      font-weight: 300;
    }
    .app-title {
      padding: 10px;
    }

    h3 {
      padding: 10px;
    }

    span {
      color: blue;
    }

    p {
      padding: 6px;
    }
    .stack {
      font-weight: 700;
      letter-spacing: 0.5px;
      background-color: lightblue;
    }
  }
}

/* MEDIA QUERIES */

@media screen and (max-width: 480px) {
  .p-dev-tools {
    width: 100%;

    .p-dev-browser {
      font-size: 0.8rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .app-details {
    width: 100%;
  }
  .p-dev-tools {
    border: 0.5px solid lightgray;
    height: auto;
  }
  .thumb-container {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .p-devtools {
    margin: 0px 0px 50px 0px;
    border-radius: 0;
    border: 0.5px solid lightgray;
    width: 100%;
    height: auto;
  }
  .thumb-container {
    display: none;
  }
  .thumb-container-sm {
    display: flex;
    color: $secondColor;
  }
}
@media screen and (max-width: 1200px) {
  .p-devtools {
    margin: 0px 0px 50px 0px;
    border-radius: 0;
    border: 0.5px solid lightgray;
    width: 100%;
    height: auto;
  }
  .thumb-container {
    display: none;
  }
  .thumb-container-sm {
    display: flex;
    color: hsl(224, 38%, 55%);
    padding: 0.5rem;
    justify-items: center;
    align-items: center;
    justify-content: center;

    .thumbnail_demo_sm {
      flex: 1;
      border: gray 1px dotted;
      padding: 6px;
      transition: all 2s ease;
    }
    .thumbnail_code_sm {
      flex: 1;
      border: gray 1px dashed;
      padding: 6px;
      transition: all 2s ease;
    }

    .thumbnail_code_sm:hover {
      color: white;
      background-color: hsl(224, 38%, 55%);
    }
    .thumbnail_demo_sm:hover {
      color: white;
      background-color: hsl(224, 38%, 55%);
    }
    a {
      color: hsl(224, 38%, 55%);
      text-decoration: none;
      transition: all 2s ease;
    }
    a:hover {
      color: white;
      background-color: hsl(224, 38%, 55%);
    }
  }
  .p-dev-tools {
    height: auto; // IT WAS TRICKY TO SET WELL
    .p-dev-browser {
      p {
        padding: 12px;
      }
    }

    .app-details {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1510px) and (min-height: 625px) and (max-height: 767px) {
  .p-dev-tools {
    height: 75vh;
    .p-dev-browser {
      p {
        padding: 12px;
      }
    }

    .app-details {
      width: 450px;
    }
  }
}
@media screen and (width: 1366px) and (min-height: 768px) and (max-height: 1080px) {
  .p-dev-tools {
    height: 75vh;
    .p-dev-browser {
      p {
        padding: 12px;
      }
    }

    .app-details {
      width: 450px;
    }
  }
}
@media screen and (min-width: 1510px) {
  .p-dev-browser {
    min-height: 20px;
    background-color: rgb(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
  }
}
@media screen and (min-width: 1367px) and (max-width: 1700px) {
  .p-dev-tools {
    width: 100%;

    .p-dev-browser {
      font-size: 0.8rem;
    }
  }
  .app-details {
    width: 31vw;
  }
}
@media screen and (min-width: 1701px) {
  .p-dev-tools {
    width: 100%;

    .p-dev-browser {
      font-size: 0.8rem;
    }
  }
  .app-details {
    width: 31vw;
  }
}
