@import "./global.scss";

.app {
  scrollbar-width: none; //for firefox
  &::-webkit-scrollbar {
    display: none;
  }
  .back-pattern {
    background-color: #282828;
    background: radial-gradient(black 15%, transparent 16%) 0 0,
      radial-gradient(black 15%, transparent 16%) 8px 8px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
    background-size: 16px 16px;
  }
}

button:hover {
  transition: 500ms linear 0s;
  filter: drop-shadow(8px 8px 10px gray);
  transform: scale(1.1);
}

button:focus {
  box-shadow: 0 0 30px rgba(31, 117, 179, 0.7);
}